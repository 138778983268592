<template>
  <section class="skill_container">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" moduleName="Skills Development">
    </FullBanner>

    <div class="container">

      <el-row style="padding:15px 5px 10px">
        <el-col :sm="24" :md="15">
          <el-input clearable @clear="getSkillCourseList" @keyup.enter.native="getSkillCourseList" class="w-100"
            placeholder="Search Skills" v-model="keyword" suffix-icon="el-icon-search"></el-input>
        </el-col>
        <el-col :sm="24" :md="9">
          <section class="mt-2 mb-1 d-flex mr-2" style="justify-content: flex-end;">
            <div class="mr-3 to_my_training" @click="openDialog">Propose a Course</div>
            <div @click="$router.push('/my_training')" class="to_my_training">
              <span>My Training</span>
            </div>
          </section>
        </el-col>
      </el-row>

      <el-row v-if="loading" class="container">
        <el-col class="cardContent p-1" :xs="24" :sm="8" v-for="(item, index) in 9" :key="index">
          <el-skeleton animated>
            <template slot="template">
              <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;">
                <el-skeleton-item variant="image" style="width: 100%; height: 160px;" />
                <div style="padding: 38px 10px 22px;height: 100px;">
                  <el-skeleton-item variant="text" style="width: 160px;height: 25px;" />
                </div>
              </iq-card>
            </template>
          </el-skeleton>
        </el-col>
      </el-row>
      <div v-if="skillList.length&&!loading">
        <Prerecording :skillList="skillList" />
      </div>
      <section v-else>
        <div class="d-flex justify-content-center" style="background:#fff;margin-top: 60px" v-if="!loading">
          <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" />
        </div>
      </section>
      <div class="d-flex justify-content-end container" v-if="skillList.length&&!loading">
        <el-pagination background layout="prev, pager, next" page-count="3" :page-size="12" @current-change="handleCurrentChange"
          :current-page.sync="pageSkill" :total="totalSkill" hide-on-single-page>
        </el-pagination>
      </div>
    </div>

    <!-- applyRequest -->
    <el-dialog title="Propose a Course" :lock-scroll="true" :visible.sync="requestDialog" width="980px" class="diaWidth" :modal="false"
      :close-on-click-modal="false">
      <section class="px-3 propose_course">
        <!-- 课程建议 -->
        <div class="course_box" v-show="is_next">
          <el-form label-position="top" label-width="80px" :model="formLabelAlign">
            <el-form-item label="Theme">
              <el-select placeholder="Please select" v-model="formLabelAlign.theme_id" clearable style="width: 100%;"
                filterable>
                <el-option v-for="(v,i) in themeList" :value="v.theme_id" :key="v.theme_id" :label="v.theme">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Event">
              <el-select placeholder="Please select" v-model="formLabelAlign.event_id" clearable style="width: 100%;"
                filterable>
                <el-option v-for="(v,i) in eventList" :value="v.event_id" :key="v.event_id" :label="v.event">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Name">
              <el-input v-model="formLabelAlign.name"></el-input>
            </el-form-item>
            <el-form-item label="Language">
              <el-select placeholder="Please select" v-model="formLabelAlign.language" clearable style="width: 100%;"
                multiple>
                <el-option v-for="(v,i) in languageList" :value="v.language" :key="v.language" :label="v.language">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Length (Hours)" style="margin-bottom: 10px;">
              <el-input-number v-model="formLabelAlign.duration" :min="1" :precision="1"></el-input-number>
            </el-form-item>
            <el-form-item label="">
              <div class="d-flex">
                <section class="mr-3 d-flex flex-column wid50">
                  <span class="star">Minimum Participants</span>
                  <el-input-number v-model="formLabelAlign.min_number" :min="1" :precision="0"></el-input-number>
                </section>
                <section class="d-flex flex-column wid50">
                  <span class="star">Maximum Participants</span>
                  <el-input-number v-model="formLabelAlign.max_number" :min="formLabelAlign.min_number" :precision="0">
                  </el-input-number>
                </section>
              </div>
            </el-form-item>
            <el-form-item label="Summary" class="no_star">
              <el-input type="textarea" :rows="3" v-model="formLabelAlign.summary"></el-input>
            </el-form-item>
            <el-form-item label="Objectives" class="no_star">
              <el-input type="textarea" :rows="3" v-model="formLabelAlign.objectives"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 讲师资料 -->
        <div class="trainer_box" v-show="!is_next">
          <el-form label-position="top" label-width="80px" :model="formLabelAlign">
            <el-form-item label="Name">
              <el-input v-model="formLabelAlign.trainer"></el-input>
            </el-form-item>
            <el-form-item label="National Organization">
              <el-select placeholder="Please select" v-model="organizationData" clearable value-key="id"
                style="width: 100%;">
                <el-option v-for="v in organizationList" :key="v.id" :label="v.name" :value="v"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Country">
              <el-select placeholder="Please select" v-model="countryData" clearable value-key="id"
                style="width: 100%;">
                <el-option v-for="v in countryList" :key="v.id" :label="v.name" :value="v"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Gender">
              <el-select v-model="formLabelAlign.gender" clearable style="width: 100%">
                <el-option label="Female" :value="0"></el-option>
                <el-option label="Male" :value="1"></el-option>
                <el-option label="Prefer not to say" :value="2"></el-option>
                <el-option label="Other" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Contact E-mail">
              <el-input v-model="formLabelAlign.contact_email"></el-input>
            </el-form-item>
            <el-form-item label="Phone">
              <el-input v-model="formLabelAlign.mobile"></el-input>
            </el-form-item>
            <el-form-item label="Head Portrait">
              <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false" :http-request="uploadIMG"
                v-loading="loading">
                <img v-if="formLabelAlign.head_portrait" :src="formLabelAlign.head_portrait" alt="" />
                <img v-else src="../../assets/images/settings/camera.png" alt="" />
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="d-flex justify-content-end mt-5 pt-4 next_apply_btn">
          <el-button type="warning" plain round @click="openHistoryDialog" class="top_btn">View History</el-button>
          <el-button @click="is_next=true" v-if="!is_next" type="info">Previous</el-button>
          <el-button type="primary" @click="toNext" v-if="is_next">Next</el-button>
          <el-button type="primary" @click="applyRequest" :disabled="loading" v-if="!is_next">Submit</el-button>
        </div>
      </section>
    </el-dialog>

    <!-- 申请记录列表 -->
    <el-dialog title="View Request History" :visible.sync="historyApply" class="diaWidth" width="980px">
      <el-table :data="historyList" style="width: 100%" tooltip-effect="light">
        <el-table-column prop="name" label="Name" show-overflow-tooltip></el-table-column>
        <el-table-column prop="theme" label="Theme" show-overflow-tooltip></el-table-column>
        <el-table-column prop="event" label="Event" show-overflow-tooltip></el-table-column>
        <el-table-column prop="language" label="Language" min-width="110" show-overflow-tooltip></el-table-column>
        <el-table-column prop="date" label="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">Pending</span>
            <span v-else-if="scope.row.status == 1">Passed</span>
            <span v-else-if="scope.row.status == 2">Fail</span>
          </template>
        </el-table-column>
        <el-table-column label="Action" width="80">
          <template slot-scope="scope">
            <div @click.stop>
              <div class="flex justify-center">
                <i class="el-icon-link cursor-pointer" style="font-size: 20px" @click="checkMaterial(scope.row)"></i>
                <i class="el-icon-view cursor-pointer" style="font-size: 20px;margin-left: 10px;"
                  @click="checkDetail(scope.row.request_id)"></i>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 20px;">
        <el-pagination :current-page="page" background layout="prev, pager, next" :total="total" :page-size="12"
          @current-change="handleChangePage" hide-on-single-page></el-pagination>
      </div>
    </el-dialog>

    <!-- 详情弹框 -->
    <el-dialog title="Training Request Detail" :visible.sync="detailPopup" width="980px" class="diaWidth detail_popup">
      <h4 class="mb-2">Request</h4>
      <section class="basic_info">
        <div class="item d-flex mb-2">
          <span class="left">Name</span>
          <div class="right">{{skillCourseInfo.name}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Language</span>
          <div class="right">{{skillCourseInfo.language}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Length (Hours)</span>
          <div class="right">{{skillCourseInfo.duration}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Minimum Participants</span>
          <div class="right">{{skillCourseInfo.min_number}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Maximum Participants</span>
          <div class="right">{{skillCourseInfo.max_number}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Summary</span>
          <div class="right">{{skillCourseInfo.summary|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Objectives</span>
          <div class="right">{{skillCourseInfo.objectives|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Training Material</span>
          <div class="right">{{skillCourseInfo.training_material_link|filterNull}}</div>
        </div>
      </section>
      <el-divider></el-divider>
      <h4 class="mb-2">Trainer</h4>
      <section class="basic_info">
        <div class="item d-flex mb-2">
          <span class="left">Name</span>
          <div class="right">{{trainingTrainer.trainer}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">National Organization</span>
          <div class="right">{{trainingTrainer.organization}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Country</span>
          <div class="right">{{trainingTrainer.country}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Gender</span>
          <div class="right">{{{0:'Female',1:'Male',2:'Prefer not to say',3:'Other'}[trainingTrainer.gender]}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Contact E-mail</span>
          <div class="right">{{trainingTrainer.contact_email}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Phone</span>
          <div class="right">{{trainingTrainer.mobile}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Head Portrait</span>
          <div class="right">
            <el-image :src="trainingTrainer.head_portrait" alt="" />
          </div>
        </div>
      </section>
    </el-dialog>
    <!-- 详情弹框 -->
    <el-dialog title="Add Course Materials" :visible.sync="materialPopup" width="630px"
      class="detail_popup material_popup">
      <el-form label-position="top" label-width="80px" :model="formLabelAlign">
        <el-form-item label="Link your documents for review">
          <el-input v-model="training_material_link"></el-input>
        </el-form-item>
      </el-form>
      <div class="d-flex justify-content-center mt-5 next_apply_btn">
        <el-button plain @click="materialPopup=false" v-if="is_next">Cancel</el-button>
        <el-button type="primary" @click="editTrainingMaterialLink">Confirm</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
  import FullBanner from "@/components/ComComponents/FullBanner";
  import Prerecording from "./Components/Prerecording";
  export default {
    components: {
      FullBanner,
      Prerecording
    },
    data() {
      return {
        skillList: [],
        formLabelAlign: {
          theme_id: '',
          event_id: '',
          language: [],
          duration: 1,
          min_number: 1,
          max_number: 1,
          summary: '',
          objectives: '',

          trainer: '',
          mobile: '',
          contact_email: '',
          head_portrait: '',
          gender: '',
        },
        requestDialog: false,
        themeList: [],
        eventList: [],
        languageList: [],
        historyApply: false,
        historyList: [],
        total: 0,
        page: 1,

        is_next: true,
        loading: false,
        countryList: [],
        organizationList: [],
        organizationData: { id: '', name: '' },
        countryData: { id: '', name: '' },
        detailPopup: false,
        skillCourseInfo: {},
        trainingTrainer: {},

        materialPopup: false,
        training_material_link: '',
        loading: true,

        totalSkill: 0,
        pageSkill: 1,
        keyword: ''
      };
    },
    created() {
      this.getSkillCourseList();
      this.getAddress()
      this.getOrganizationList()
    },

    methods: {
      handleCurrentChange(page) {
        this.pageSkill = page;
        this.getSkillCourseList();
      },

      // 获取项目列表
      getSkillCourseList() {
        this.loading = true
        this.$http.getSkillCourseList({
          page: this.pageSkill,
          per_page: 12,
          keyword: this.keyword
        }).then((res) => {
          if (res.status == 200) {
            this.skillList = res.data.data;
            this.totalSkill = res.data.total;
            setTimeout(() => {
              this.loading = false
            }, 0);
          }
        });
      },

      // 打开申请弹框
      openDialog() {
        this.getEventList()
        this.getTrainingThemeList()
        this.getLangList()
        Object.keys(this.formLabelAlign).forEach(k => {
          this.formLabelAlign[k] = ''
        })
        this.formLabelAlign.min_number = 1
        this.countryData = { id: '', name: '' }
        this.organizationData = { id: '', name: '' }
        this.is_next = true
        this.requestDialog = true
      },

      // 打开申请记录弹框
      openHistoryDialog() {
        this.page = 1
        this.getRequestList()
        this.historyApply = true
      },

      async checkDetail(request_id) {
        let res = await this.$http.getRequestInfo({ request_id })
        if (res.status == 200) {
          this.detailPopup = true
          this.skillCourseInfo = res.data
          this.trainingTrainer = res.data.training_trainer
        }
      },

      // 获取国家列表
      async getAddress() {
        let { status, data } = await this.$http.getAddress({ level: 2 })
        if (status == 200) {
          this.countryList = data;
        }
      },

      // 获取组织列表
      async getOrganizationList() {
        let res = await this.$http.getOrganizationList({ type: 2 });
        if (res.status == 200) {
          this.organizationList = res.data;
        }
      },

      // 获取主题培训列表
      getTrainingThemeList() {
        this.$http.getTrainingThemeList().then((res) => {
          if (res.status == 200) {
            this.themeList = res.data;
          }
        });
      },

      // 上传图片
      uploadIMG(params) {
        var formData = new FormData();
        formData.append("file", params.file);
        this.loading = true
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.formLabelAlign.head_portrait = res.data[0];
          }
          setTimeout(() => { this.loading = false }, 1000);
        });
      },

      // 获取活动列表
      getEventList() {
        this.$http.getEventList().then((res) => {
          if (res.status == 200) {
            this.eventList = res.data;
          }
        });
      },

      // 获取语言
      async getLangList() {
        let res = await this.$http.getWebLanguageList();
        this.languageList = res.data;
      },

      // 申请建议书
      async applyRequest() {
        this.formLabelAlign.organization = this.organizationData.name
        this.formLabelAlign.organization_id = this.organizationData.id
        this.formLabelAlign.country_id = this.countryData.id
        this.formLabelAlign.country = this.countryData.name
        let res = await this.$http.applyRequest({ ...this.formLabelAlign });
        if (res.status == 200) {
          this.requestDialog = false
        }
      },

      //  获取建议书列表
      async getRequestList() {
        let res = await this.$http.getRequestList({ page: this.page })
        if (res.status == 200) {
          this.historyList = res.data.data;
          this.total = res.data.total;
        }
      },

      handleChangePage(num) {
        this.page = num;
        this.getRequestList();
      },

      toNext() {
        if (this.formLabelAlign.min_number > this.formLabelAlign.max_number) {
          this.$message.warning('The minimum participant amount cannot exceed the maximum participant amount.')
        } else {
          this.is_next = false
        }
      },

      checkMaterial(item) {
        this.training_material_link = item.training_material_link
        this.materialPopup = true
        this.request_id = item.request_id
      },

      async editTrainingMaterialLink() {
        let res = await this.$http.editTrainingMaterialLink({ request_id: this.request_id, training_material_link: this.training_material_link })
        if (res.status == 200) {
          this.$message.success(res.message)
          this.getRequestList()
          this.materialPopup = false
        }
      }

    }

  };
</script>
<style lang="scss" scoped>
  .skill_container {
    min-height: 100vh;

    .title {
      margin-bottom: 18px;
    }

    .profile-info {
      min-height: 130px;
    }

    .search_box{
      
    }
  }

  .to_my_training {
    margin: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid #EDBE38;
    color: #EDBE38;
    line-height: 18px;
  }

  .propose_course {
    position: relative;

    /deep/ .el-form-item__label {
      line-height: 20px;
    }

    /deep/ .el-form--label-top .el-form-item__label {
      padding: 0
    }

    /deep/ .el-form-item__label::before,
    .star::before {
      display: inline-block;
      content: '*';
      color: red;
    }

    /deep/ .no_star .el-form-item__label::before {
      display: none;
      content: '*';
      color: red;
    }

    .top_btn {
      position: absolute;
      top: -60px;
      right: 30px;
    }

    .avatar-uploader {
      width: 90px;
      height: 90px;
      overflow: hidden;

      img {
        width: 90px;
        height: 90px;
      }
    }
  }

  .detail_popup {
    .basic_info {
      .left {
        width: 200px;
      }

      .right {
        color: #999;
        flex: 1;

        ::v-deep p {
          margin: 0 !important;
          padding: 0 !important;
        }

        ::v-deep img {
          width: 90px;
          height: 90px;
        }
      }
    }
  }

  .material_popup {
    ::v-deep .el-form-item__label {
      line-height: 22px;
      padding: 0px
    }
  }
  @media (min-width: 600px) {
  }
  @media (max-width: 600px) {
    .container {
      padding: 0 8px;
    }
    ::v-deep .el-dialog{
      width: 100% !important;
      margin: 0 !important;
      .el-form-item__label{
        font-size: 12px;
      }
    }
    .wid50{
      width: 50%;
      font-size: 12px;
      .el-input-number{
        width: 100%;
      }
    }
  }
</style>